import http from "./httpService";
import jwt from "jsonwebtoken";
import { apiUrl } from "../config.json";
import { secretKey } from "../config.json";
import CryptoJS from "crypto-js";

const tokenKey = "tokenPtDtn";
const refreshTokenKey = "refreshTokenPtDtn";

//http.setJwt(getJwt());

export async function login(values) {
  const { data } = await http.post(`${apiUrl}/v1/auth`, values);

  // Decrypt
  var bytes = CryptoJS.AES.decrypt(data.payload, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  localStorage.setItem(tokenKey, decryptedData.token);
  localStorage.setItem(refreshTokenKey, decryptedData.refreshToken);
}

export function loginWithJwt(token) {
  localStorage.setItem(tokenKey, token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem(tokenKey);
    return jwt.decode(token);
  } catch (ex) {
    return null;
  }
}

export function getRefreshToken() {
  try {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    return jwt.decode(refreshToken);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getRefreshToken,
};
